import { Theme } from 'app'
import { React, StyleSheet, View, Text, CenterWrapper, onUpdate, useState } from 'lib'

import marie_claire from '../../assets/press/Marie_Claire.png'
import metro from '../../assets/press/Metro.png'
import forbes from '../../assets/press/Forbes.png'
import balance from '../../assets/press/Balance.png'
import stylus from '../../assets/press/Stylus.png'
import sustain_health from '../../assets/press/Sustain_Health.png'

const heading = 'Becon In The Press'

const items = [
  {
    logo: marie_claire,
    body:
      <Text variant={['p3', 'textCenter', 'marginTop:2', 'inline']}>
        “One of the ways we can gain a little peace-of-mind is by using <strong>Becon</strong>. The app will monitor your journey and alert your loved ones if it detects any worrying signals.”
      </Text>,
  },
  {
    logo: metro,
    body:
      <Text variant={['p3', 'textCenter', 'marginTop:2', 'inline']}>
        “<strong>Becon</strong> is the must-download app for late night travel on foot.“
      </Text>,
  },
  {
    logo: forbes,
    body:
      <Text variant={['p3', 'textCenter', 'marginTop:2', 'inline']}>
        “<strong>Becon</strong> is a simple-to-use safety app that keeps an eye out for you, until you reach your destination.”
      </Text>,
  },
  {
    logo: balance,
    body:
      <Text variant={['p3', 'textCenter', 'marginTop:2', 'inline']}>
        “When walking, stuff happens. A quick installation of <strong>Becon</strong> and you’ll never need to worry about “letting them know” again.”
      </Text>,
  },
  {
    logo: stylus,
    body:
      <Text variant={['p3', 'textCenter', 'marginTop:2', 'inline']}>
        “Smart safety app <strong>Becon</strong> brings pedestrians peace-of-mind.”
      </Text>,
  },
  {
    logo: sustain_health,
    body:
      <Text variant={['p3', 'textCenter', 'marginTop:2', 'inline']}>
        “<strong>Becon</strong> is a revolutionary new digital tool, created to empower people to feel safer, more confident and independent when making active journeys.”
      </Text>,
  },
]

const BeconInPress: React.FC = () => {

  const [sectionHeight, setSectionHeight] = useState(null)
  const { width } = Theme.hooks.size()

  onUpdate(() => {
    const finalHeight = document.getElementById('#how-it-works')?.clientHeight + Theme.spacing(6)
    if (finalHeight && finalHeight != sectionHeight) {
      setSectionHeight(finalHeight)
    }
  }, [width])

  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper} id='#how-it-works'>
      <Text
        text={heading}
        variant={['h4', 'textCenter']}
      />
      <View style={styles.itemGrid}>
        {items.map(({ logo, body }, key) => (
          <View variant={['flex', 'column', 'alignCenter']} style={styles.buttons} key={key}>
            <View
              style={[
                styles.logo,
                {
                  backgroundImage: `url(${logo})`,
                },
              ]}
            />
            <View variant={['flex', 'column', 'alignCenter']}>
              {body}
            </View>
          </View>
        ))}
      </View>
    </CenterWrapper>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    position: 'relative',
    backgroundColor: Theme.colors.white,
  },
  innerWrapper: {
    marginTop: Theme.spacing(12),
    marginBottom: Theme.spacing(12),
    display: 'flex',
    flexWrap: 'wrap',
    gap: Theme.spacing(3),
    ...Theme.justifyCenter,
    ...Theme.alignCenter,
  },
  itemGrid: {
    display: 'grid',
    gap: Theme.spacing(2),

    [Theme.media.up('mid')]: {
      marginTop: Theme.spacing(5),
      marginBottom: Theme.spacing(5),
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(3),
      marginBottom: Theme.spacing(3),
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [Theme.media.down('small')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  buttons: {
    maxWidth: 400,
    justifyContent: 'space-between',
    padding: Theme.spacing(3),
    borderRadius: Theme.spacing(2.25),
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  text: {
    maxWidth: '80%',
  },
  logo: {
    width: 250,
    height: 50,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
  },
})

export default BeconInPress
